import { default as _91_46_46_46cms_93arqctiV5dVMeta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93WqPFvxp163Meta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/blog/[...slug].vue?macro=true";
import { default as indexQAvZAbWjynMeta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93jXqsKwYjYeMeta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/catalog/[...slug].vue?macro=true";
import { default as temp_catalog_need_32_removeYBe5zly2HFMeta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/catalog/temp_catalog_need _remove.vue?macro=true";
import { default as _91_46_46_46slug_93wLZGOfSSRVMeta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/product/[...slug].vue?macro=true";
import { default as indexr0MfRCMRd0Meta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/product/index.vue?macro=true";
import { default as _91_46_46_46slug_93PHSoNBWkpbMeta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/realty/[...slug].vue?macro=true";
import { default as indexNnAxENU2eHMeta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/sitemap/index.vue?macro=true";
import { default as index88Bx6uu0e0Meta } from "/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/wish/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93arqctiV5dVMeta?.name ?? "cms",
    path: _91_46_46_46cms_93arqctiV5dVMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93arqctiV5dVMeta || {},
    alias: _91_46_46_46cms_93arqctiV5dVMeta?.alias || [],
    redirect: _91_46_46_46cms_93arqctiV5dVMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93WqPFvxp163Meta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93WqPFvxp163Meta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93WqPFvxp163Meta || {},
    alias: _91_46_46_46slug_93WqPFvxp163Meta?.alias || [],
    redirect: _91_46_46_46slug_93WqPFvxp163Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexQAvZAbWjynMeta?.name ?? "blog",
    path: indexQAvZAbWjynMeta?.path ?? "/blog",
    meta: indexQAvZAbWjynMeta || {},
    alias: indexQAvZAbWjynMeta?.alias || [],
    redirect: indexQAvZAbWjynMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jXqsKwYjYeMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93jXqsKwYjYeMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93jXqsKwYjYeMeta || {},
    alias: _91_46_46_46slug_93jXqsKwYjYeMeta?.alias || [],
    redirect: _91_46_46_46slug_93jXqsKwYjYeMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: temp_catalog_need_32_removeYBe5zly2HFMeta?.name ?? "catalog-temp_catalog_need _remove",
    path: temp_catalog_need_32_removeYBe5zly2HFMeta?.path ?? "/catalog/temp_catalog_need%20_remove",
    meta: temp_catalog_need_32_removeYBe5zly2HFMeta || {},
    alias: temp_catalog_need_32_removeYBe5zly2HFMeta?.alias || [],
    redirect: temp_catalog_need_32_removeYBe5zly2HFMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/catalog/temp_catalog_need _remove.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wLZGOfSSRVMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93wLZGOfSSRVMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93wLZGOfSSRVMeta || {},
    alias: _91_46_46_46slug_93wLZGOfSSRVMeta?.alias || [],
    redirect: _91_46_46_46slug_93wLZGOfSSRVMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexr0MfRCMRd0Meta?.name ?? "product",
    path: indexr0MfRCMRd0Meta?.path ?? "/product",
    meta: indexr0MfRCMRd0Meta || {},
    alias: indexr0MfRCMRd0Meta?.alias || [],
    redirect: indexr0MfRCMRd0Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93PHSoNBWkpbMeta?.name ?? "realty-slug",
    path: _91_46_46_46slug_93PHSoNBWkpbMeta?.path ?? "/realty/:slug(.*)*",
    meta: _91_46_46_46slug_93PHSoNBWkpbMeta || {},
    alias: _91_46_46_46slug_93PHSoNBWkpbMeta?.alias || [],
    redirect: _91_46_46_46slug_93PHSoNBWkpbMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/realty/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexNnAxENU2eHMeta?.name ?? "sitemap",
    path: indexNnAxENU2eHMeta?.path ?? "/sitemap",
    meta: indexNnAxENU2eHMeta || {},
    alias: indexNnAxENU2eHMeta?.alias || [],
    redirect: indexNnAxENU2eHMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: index88Bx6uu0e0Meta?.name ?? "wish",
    path: index88Bx6uu0e0Meta?.path ?? "/wish",
    meta: index88Bx6uu0e0Meta || {},
    alias: index88Bx6uu0e0Meta?.alias || [],
    redirect: index88Bx6uu0e0Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241010095912/pages/wish/index.vue").then(m => m.default || m)
  }
]